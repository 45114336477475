import React, { useEffect, useState } from 'react'
import { graphql, Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import ProjectCarousel from '../components/project-carousel'
import ProjectImage from '../components/project-image'

import * as styles from './project.module.css'

const ProjectPage = ({ data }) => {
  let media
  let pageShareImage

  // Sometimes authors might create an image entry and accidentally leave it blank.
  // If that happens, we want to filter out the blanks so the component doesn't crash
  // looking for data that doesn't exist.
  const validImages = data.strapiProject.images?.filter(entry => entry?.image|| entry?.vimeoUrl) || [];

  if (validImages.length > 0) {
    if (validImages.length > 1) {
      media = <ProjectCarousel slides={validImages} />
    } else {
      media = <ProjectImage media={validImages[0]} />
    }

    pageShareImage = validImages[0]
  }

  const creditsData = data.strapiProject.credits || []
  const [credits, setCredits] = useState(null)

  useEffect(() => {
    if (!creditsData || !creditsData.length) {
      return
    }

    const useDesktopView = window.innerWidth >= 640
    const creditsToUse = useDesktopView ? creditsData : creditsData.slice(0, 8)
    let columns = 2;
    if (useDesktopView) {
      if (creditsToUse.length === 6 || creditsToUse.length === 3) {
        columns = 3
      } else if (creditsToUse.length === 4) {
        columns = 4
      } else if (creditsToUse.length > 1) {
        columns = 2
      }
    }

    let creditsMarkup = creditsToUse.map(
      ({ role, name, organization, link, id }) => {
        let credit = (
          <>
            <em>{role}</em> {name}{' '}
            {organization && (
              <>
                <em>at</em> {organization}
              </>
            )}
          </>
        )
        if (link) {
          credit = <Link to={link}>{credit}</Link>
        }
        return (
          <div key={id} className={styles.credit} style={{ flex: `0 0 ${1 / columns * 100}%`}}>
            {credit}
          </div>
        )
      }
    )
    creditsMarkup = (
      <div className={[styles.metadata, "caption"].join(" ")}>
        <div className={styles.credits}>
          {creditsMarkup}
        </div>
      </div>
    )
    setCredits(creditsMarkup)
  }, [creditsData])

  const breadcrumbLink = data.strapiProject.category?.slug
    ? `/${data.strapiProject.category?.slug}`
    : ''

  return (
    <Layout showSiteTitle={false} breadcrumbText="back" breadcrumbLink={breadcrumbLink}>
     
      <div className={`${styles.project} page-content--grow`}>
        {media}
        {creditsData.length > 0 && credits}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ProjectQuery($slug: String!) {
    strapiProject(slug: { eq: $slug }) {
      title
      slug
      category {
        name
        slug
      }
      credits {
        id
        link
        name
        organization
        role
      }
      images {
        id
        vimeoUrl
        image {
          alternativeText
          ext
          hash
          height
          mime
          name
          size
          url
          width
        }
      }
      SEO {
        metaDescription
        keywords
        shareImage {
          alternativeText
          ext
          hash
          height
          mime
          name
          size
          url
          width
        }
      }
    }
  }
`

// export async function config() {
//   return () => {
//     return {
//       defer: true
//     }
//   }
// }

export default ProjectPage
